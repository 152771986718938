import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/style/index.less';
import i18n from './lang/index';
// import http from './http/index';

Vue.config.productionTip = false;

Vue.use(ElementUI);
// Vue.use(http);

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
