module.exports = {
  home: {
    wrong: 'The wrong chain',
    copySuccess: 'copy successfully',
    connect: 'Connect Wallet',
    intro: 'project introduction & ecosystem',
    buy: 'Buy',
    bannerText1: 'Fomo Shiba Inu is a decentralized Meme token community, an ecosystem full of potential.',
    bannerText2: 'The Fomo Shiba Token project is a community autonomous Token deployed on the BSC chain. Community members are growing fast!',
    howToBuy: 'How to Buy',
    readWhite: 'Read WoofPaper',
    introDes: 'Fomo Shiba Inu, the total amount of deflationary tokens is 1,000 trillion. The shiba Inu coins are not to be missed. For each transaction: 3% burn, 3% add FOMOSHIB&USDT liquidity to PancakeSwap, 3% exchange for USDT into escrow contract, 80% of total tokens locked into pancake through liquidity, 20% of total tokens reserved by team.',
    about: 'About Escrow Agreement: ',
    aboutDes: 'Escrow Agreement is a mechanism unique to FOMOSHIB. Ensures FOMOSHIB has real value. The backstop contract specifically houses the USDT, The user can choose to destroy FomoDoge tokens and get the USDT from the backstop contract for the current amount destroyed as a percentage of the total FOMOSHIB tokens. This mechanism guarantees a minimum price for tokens, as the number of transactions increases, the more USDT there is in the escrow contract, the less the total amount of FOMOSHIB tokens. The more USDT each FOMOSHIB can redeem from the destruction of the backing contract.',
    cardLine1: '80% liquidity lock',
    cardLine2: 'To Pancakeswap',
    cardLine3: '20% Founding Team',
    cardLine4: 'Follow-up application development',
    fomoIntro: 'FomoShiba Inu tokens are our base currency, allowing investors to hold millions, billions or even trillions of tokens in their wallets.',
    fomoDes1: 'All administrator privileges are waived for contract deployers.',
    fomoDes2: 'Later the team will develop an NFT+ blind box card game as an upper application for FOMOSHIB.',
    buyWay: 'SHIB is best purchased and sold through Pancakeswap.',
    buySug: 'FOMOSHIB is a decentralized experiment, so we always encourage DEX trading. If you choose to use CEX, remember to ensure that it is both secure and reliable.',
    buyAddress: 'Contract Address',
    totalNum: 'The initial amount',
    leftNum: 'The remaining amount',
    destoryNum: 'The destoryed amount',
    destory: 'Destory',
    pleaseEnter: 'Please enter the destruction quantity',
    balance: 'Your token balance is:',
    price: 'The price of each token is ≈ ',
    startGuide: 'Quick Start Guide',
    stepTitle1: 'Create MetaMask wallet',
    stepDes1: 'Create a MetaMask Wallet using either a desktop computer or an iOS/Android mobile device. That will allow you to buy, sell, send, and receive OMOSHIB.',
    stepTitle2: 'Connect your wallet to pancakeswap',
    stepDes2: 'Access your wallet to pancakeswap by clicking "Connect to Wallet" and selecting MetaMask.',
    stepTitle3: 'Send BNB to your wallet',
    stepDes3: 'You can buy BNB directly on MetaMask or transfer it to the MetaMask wallet from exchanges like Coinbase and Binance. When transferring BNB, make sure to use the ERC-20 network.',
    stepTitle4: 'Swap BNB for FOMOSHIB',
    stepDes4: 'You can start swapping as soon as you have BNB available! Press ‘Select a token’ and enter the token address or search for it on the tokens list.',
    art: 'Art',
    artDes: 'There will be an artwork about FOMOSHIB in the future. The work comes from the development team and fans of FOMOSHIB.',
    community: 'Community',
    communityDes: "If you'd like to donate to the Devs, please send it here.",
    or: 'or',
    communityThank: 'Thank you for your support! Donations from the account are used for community development.',
    copy: 'Copy',
    starDes: 'Our community is getting stronger every day. Please follow our social media for the latest and accurate FOMOSHIB information.',
    linkDes: "Use the links below to join FomoShibArmy's more than 1 million other members on our various panels.",
    clickDes: 'Click here to search Telegram groups in your current language and region!',
    group: 'group'
  }
};